import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';

import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { LoginRegisterModalComponent } from 'src/app/client/budget-details/login-register-modal/login-register-modal.component';
import { UserRole } from 'src/definitions/user-role.enum';
import { UserService } from 'src/services/user.service';
import { AuthService } from 'src/shared-components/ng-login/auth/auth.service';
import { LoadingStore } from 'src/stores/loading.store';

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    RouterLink,
    MatButtonModule,
    TranslateModule,
    MatIcon
  ]
})
export class LoginFormComponent implements OnInit, OnDestroy {
  /**
   * Login form
   */
  public loginForm: FormGroup;

  /**
   * Indicator that the component is destroyed
   * @private
   */
  private destroyed = new Subject();

  /**
   * The user role
   */
  role: UserRole;

  /**
   * Value to show an icon or text for password field
   */
  showPassword = false;

  /**
   * Modal data if the form is called from a modal
   */
  @Input() modal: MatDialogRef<LoginRegisterModalComponent> | undefined;

  /**
   * Event emitter that will trigger an event when the login  is correct.
   */
  @Output() logged: EventEmitter<string | null> = new EventEmitter();

  translateService: TranslateService | undefined;

  /**
   * Translation key for the invalid login message
   */
  invalidLoginMessage = 'AuthService.IncorrectEmailPassword';

  /**
   * Indicates if there is an error in the login
   */
  loginError = false;

  /**
   * Component constructor
   */
  constructor(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @Inject(PLATFORM_ID) private platformId: object,
    private authService: AuthService,
    private inj: Injector,
    public loading: LoadingStore,
    private userService: UserService
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
    // The role is client
    this.role = UserRole.CLIENT;
  }
  /**
   * Creates the login form fields and their validations
   */
  ngOnInit(): void {
    this.translateService = this.inj.get(TranslateService);
    // If already logged go to home
    if (this.authService.loggedIn()) {
      this.logged.emit(null);
    }
  }

  /**
   * Method called on the event 'onSubmit', if an access_token is received then navigates to home otherwise shows
   * the error
   */
  public login(): void {
    this.translateService = this.inj.get(TranslateService);
    if (this.loginForm.valid) {
      this.loading.start();
      this.loginForm.disable();
      this.authService
        .login(this.loginForm.value, this.role)
        .pipe(skip(1), takeUntil(this.destroyed))
        .subscribe((loginOk: any) => {
          if (loginOk) {
            if (!this.modal) {
              this.authService.successLoginInfoAndRedirect(loginOk, this.role);
            } else {
              this.authService.successLoginInfoAndRedirect(loginOk, this.role, true);
              this.modal.close(loginOk);
            }
          } else {
            this.userService.checkBlockedAccount(this.loginForm.value.email).subscribe(
              (data) => {
                if (data.blocked) {
                  this.loginError = !loginOk;
                  this.invalidLoginMessage = 'AuthService.BlockedAccount';
                } else {
                  this.loginError = !loginOk;
                }
              },
              (_error) => {
                if (_error.error.status === 'Not Found') {
                  this.loginError = !loginOk;
                  this.invalidLoginMessage = 'AuthService.IncorrectEmailPassword';
                } else {
                  this.loginError = !loginOk;
                }
              }
            );
          }
        })
        .add((): void => {
          this.loading.stop();
        });
    }

    this.loginForm.enable();
  }

  /**
   * Method to show or hide the password
   * @param event
   */
  togglePasswordVisibility(event: MouseEvent): void {
    this.showPassword = !this.showPassword;
    if (event && isPlatformBrowser(this.platformId)) {
      event.stopImmediatePropagation();
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(null);
    this.destroyed.complete();
  }
}
